import categoryApi from '~/api/category';

const dataHeaderNavigation: any = [
  {
    name: 'Shop',
    url: '/shop/catalog',
    submenu: {
      type: 'menu',
      menu: [],
    },
  },
  {
    name: 'Orders',
    url: '/shop/cart',
    submenu: {
      type: 'menu',
      menu: [
        {
          name: 'Cart',
          url: '',
          children: [{ name: 'Orders In Cart', url: '/shop/cart' }],
        },
        { name: 'My Orders', url: `${process.env.URL}/dashboard/myorders/` },
        { name: 'My RFQs', url: `${process.env.URL}/dashboard/myrfqs/` },
        { name: 'Terms And Conditions', url: '/site/terms' },
      ],
    },
  },
  {
    name: 'Catalogues',
    url: '/catalogues',
  },
  {
    name: 'Account',
    url: '/account/dashboard',
    submenu: {
      type: 'menu',
      menu: [
        {
          name: 'Update Profile',
          url: `${process.env.URL}/dashboard/account/update-profile/`,
        },
        {
          name: 'My Feedback',
          url: `${process.env.URL}/dashboard/myfeedbacks/`,
        },
        {
          name: 'My Incidents',
          url: `${process.env.URL}/dashboard/myincidents/`,
        },
      ],
    },
  },
  {
    name: 'Company',
    submenu: {
      type: 'menu',
      menu: [
        {
          name: 'About Us',
          url: '/site/about-us',
        },
        {
          name: 'Contact Us',
          children: [
            { name: 'Contact Us', url: '/site/contact-us' },
            { name: 'Feedback', url: '/site/feedback' },
          ],
        },
        {
          name: 'Communications',
          children: [
            { name: 'Blog', url: '/blog/category-left-sidebar' },
            { name: 'News', url: '/news/category-left-sidebar' },
          ],
        },
        {
          name: 'Policies',
          children: [{ name: 'Terms Of Use', url: '/site/terms' }],
        },
      ],
    },
  },
];

export default dataHeaderNavigation;
let menu: any = dataHeaderNavigation[0].submenu.menu;
loadCategories();

async function loadCategories() {
  const { data }: any = await categoryApi.list({
    'page[size]': 1000,
    status: 'O',
  });

  const url = (data: any) => {
    return `/shop/catalog/${data.id}`;
  };

  if (data) {
    data.forEach((e: any) => {
      e.url = url(e);
      e.children = e.children?.data;

      if (e && e.children) {
        e.children.forEach((f: any) => {
          delete f.children;
          f.url = url(f);
        });
      }

      if (e.parentid == 0) {
        menu.push(e);
      }
    });
  }
}
